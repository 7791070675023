<template>
  <div> 
    <p> {{ $t('online.numberTextQuizEs.metaDescription') }} </p>
    <p> {{ $t('online.numberTextQuizEs.howItWorksBody') }} </p>
    <div class="messages">
      <p class="fail-msg" v-show="!success" > {{ $t('online.numberTextQuizEs.failureMessage', [correctAnswer]) }} </p>
      <p class="success-msg" v-show="success && !hideSuccessMessage"> {{ $t('online.numberTextQuizEs.successMessage') }} </p>
    </div>
    <p class="question"> {{ questionLabel + ': ' + question }} </p>
    <v-text-field
      ref="answerInput"
      :value="submittedAnswer"
      :label="answerLabel"
      clearable
      autofocus
      @keyup.enter="submitAsnwer"
      @input="onAnswerChange"
    />
    <h3> {{ $t('online.numberTextQuizEs.stats') }} </h3>
    <br>
    {{ $t('online.numberTextQuizEs.totalAnswers', [stats.totalAnswers]) }}
    <br>
    {{ $t('online.numberTextQuizEs.correctAnswers', [stats.correctAnswers, correctPercentage]) }}
    <br>
    {{ $t('online.numberTextQuizEs.longestStreak', [stats.longestStreak]) }}
    <br>
    {{ $t('online.numberTextQuizEs.currentStreak', [stats.currentStreak]) }}
  </div>
</template>

<script>

const SpanishNumber = require('spanish-number');

export default {
  name: "TextQuiz",
   props: {
    questionLabel: {
      type: String,
      reqired: true
    },
    answerLabel: {
      type: String,
      reqired: true
    },
  },
  data() {
    return {
      correctAnswer: null,
      hideSuccessMessage: true,
      submittedAnswer: null,
      success: true,
      question: null,
      stats: {
        totalAnswers: 0,
        correctAnswers: 0,
        currentStreak: 0,
        longestStreak: 0,
      }
    };
  },
  created() {
    this.generateQuestion();
  },
  computed: {
    correctPercentage() {
      if (this.stats.totalAnswers > 0) {
        return Math.round(this.stats.correctAnswers / this.stats.totalAnswers * 100);
      } else {
        return '--';
      }
    },
  },
  methods: {
    generateQuestion() {

      var number = Math.floor(Math.random() * 1000);
      var pronounciation = SpanishNumber.nameOf(number).toLowerCase();

      if (Math.random() < 0.5) {
        this.question = number;
        this.correctAnswer = pronounciation;
      } else {
        this.question = pronounciation;
        this.correctAnswer = number;
      }
    },
    onAnswerChange(answer) {
      this.submittedAnswer = answer;
      this.hideSuccessMessage = true;
    },
    submitAsnwer() {
      this.stats.totalAnswers++;
      this.success = this.submittedAnswer == this.correctAnswer;
      if (this.success) {
        this.$refs.answerInput.reset();
        this.hideSuccessMessage = false;
        this.stats.correctAnswers++;
        this.stats.currentStreak++;
        if (this.stats.longestStreak < this.stats.currentStreak) {
          this.stats.longestStreak = this.stats.currentStreak;
        }
        this.generateQuestion();
      } else {
        this.stats.currentStreak = 0;
      }
    },
  },
};

</script>

<style scoped>

.fail-msg {
  color: red;
}

.messages {
  margin-top: 50px;
  height: 80px;
}

.success-msg {
  color: green;
}

.question {
  font-size: 20px;
}

</style>
