<template>
    <div style="padding: 10px;">
        <h2>{{ header }}</h2>
        <div v-for="(calc, i) in onlineCalculators" :key="i" class="onlineLink" >
            <a :href="calc.url">{{ calc.title }} </a>
        </div>
        <div v-show="!response.loading">
            <div v-for="(app, i) in response.apps" :key="i" class="app">
                <a :href="'/app/' + app.publicId">
                    <div>
                        <h3>{{ app.name }}</h3>
                        <div style="margin-top: 10px; margin-bottom: 20px;">{{ app.description }}</div>
                        <div style="height: 236px;">
                            <v-img :src="$api.imageUrl(app.imageId)" contain max-width="351" max-height="236" />
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <!-- TODO: show when app is shown in play store again
        <div v-show="response.loading" style="width: 10px; height: 338px; display: block;">
        </div>
        <div class="app">
            <a href="https://play.google.com/store/apps/details?id=com.jardoapps.kalkules">
                <div>
                    <h3>{{ $t("appList.kalkulesForAndroid") }}</h3>
                    <img src="@/assets/img/kalkules_android_screen.png" width="200" height="399" :alt="$t('appList.kalkulesForAndroid')" style="display: block; margin: 10px auto 0px auto;" />
                </div>
            </a>
        </div>
        -->
    </div>
</template>
<script>
export default {
    name: "AppList",
    props: {
        excludedAppId: String,
        caption: String,
    },
    computed: {
        header: function() {
            return this.caption ? this.caption : this.$t("appList.tryAlso");
        },
        locale: function() {
            return this.$store.state.locale;
        },
    },
    created() {
        this.fetchData();
        this.refreshOnlineCalculators();
    },
    data() {
        return {
            response: {
                loading: true,
                apps: [],

            },
            onlineCalculators: []
        };
    },
    methods: {
        async fetchData() {
            var url = "app/list?lang=" + this.$store.state.locale;
            if (this.excludedAppId) {
                url = url + "&exclude=" + this.excludedAppId;
            }
            const response = await this.$api.get(url);
            const apps = await response.data.apps;
            this.response = {
                loading: false,
                apps: apps,
            }
        },
        refreshOnlineCalculators() {
            this.onlineCalculators = [
                {
                    url: this.$t('online.bmiCalculator.url'),
                    title: this.$t('online.bmiCalculator.metaTitle'),
                },
                {
                    url: this.$t('online.dateCalculator.url'),
                    title: this.$t('online.dateCalculator.metaTitle'),
                },
                {
                    url: this.$t('online.euroCalculator.url'),
                    title: this.$t('online.euroCalculator.metaTitle'),
                },
                {
                    url: this.$t('online.percentageCalculator.url'),
                    title: this.$t('online.percentageCalculator.metaTitle'),
                },
                {
                    url: this.$t('online.numberTextQuizEs.url'),
                    title: this.$t('online.numberTextQuizEs.title'),
                },
            ];
        },
    },
    watch: {
        locale() {
            this.fetchData();
            this.refreshOnlineCalculators();
        },
    },
}
</script>
<style scoped>
.app {
    margin-top: 20px;
}
.app a {
    text-decoration: none;
    color: black;
}
.app img {
    object-fit: contain;
}
.onlineLink {
    margin-top: 10px;
}
</style>