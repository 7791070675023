<template>
    <v-row align="center">
        <v-text-field v-model="sourceValue" type="number" class="converterInput" />
        <span> {{ sourceUnit }} = {{ targetValue }} {{ targetUnit }}</span>
    </v-row>
</template>

<script>

export default {
    name: "SimpleUnitConverter",
    props: {
        sourceUnit: {
            type: String,
            required: true,
        },
        targetUnit: {
            type: String,
            required: true,
        },
        conversionRate: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            sourceValue: 0,
        }
    },
    computed: {
        targetValue() {
            return Math.round(this.sourceValue * this.conversionRate * 100) / 100;
        },
    },
};
</script>

<style scoped>
.v-text-field {
    flex: none;
    width: 150px;
    margin-left: 20px;
    margin-right: 10px;
}
</style>