<template>
  <v-select :items="items" v-model="selectedLocale" />
</template>

<script>
import { SUPPORTED_LOCALES } from "@/i18n";
export default {
  name: "LanguageSelector",
  computed: {
    items: function() {
      var items = [];
      for (var i = 0; i < SUPPORTED_LOCALES.length; i++) {
        var locale = SUPPORTED_LOCALES[i];
        items.push({
          text: locale,
          value: locale,
        });
      }
      return items;
    },
    selectedLocale: {
      get: function() {
        return this.$store.state.locale;
      },
      set: function(newVal) {
        this.$store.dispatch("changeLocale", newVal);
      },
    },
  },
};
</script>
