<template>
  <DoubleColumnLayout>
    <template v-slot:default>

      <v-alert type="info">
         {{ $t('download.startMessage') }}
      </v-alert>

      <!-- Kalkules download page -->
      <ConditionalAdsense
        data-ad-client="ca-pub-1338414232761221"
        data-ad-slot="5952021836"
        data-ad-format="auto"
        data-full-width-responsive="true"
      >
      </ConditionalAdsense>

    </template>
    <template v-slot:right>
      <AppList :excludedAppId="$route.params.publicId" />
    </template>
  </DoubleColumnLayout>
</template>

<script>
// @ is an alias to /src
import AppList from "@/components/AppList.vue";
import ConditionalAdsense from "@/components/ConditionalAdsense.vue";
import DoubleColumnLayout from "@/components/DoubleColumnLayout.vue";

export default {
  name: "Download",
  components: {
    AppList,
    ConditionalAdsense,
    DoubleColumnLayout,
  },
  created() {
    window.setTimeout( () => {
       window.open(this.$api.url("file/app/" + this.$route.params.file), '_self');
    }, 3000);
  },  
};
</script>
