import Axios from "axios";

class AppService {
  _dataUrl(relativeUrl) {
    var base = window.__IS_PRERENDERING
      ? process.env.VUE_APP_API_BASE_URL_PRERENDER
      : process.env.VUE_APP_API_BASE_URL;
    return base + "/api/v1/" + relativeUrl;
  }
  url(relativeUrl) {
    return process.env.VUE_APP_API_BASE_URL + "/api/v1/" + relativeUrl;
  }
  imageUrl(imageId) {
    return process.env.VUE_APP_API_BASE_URL + "/api/v1/file/img/" + imageId;
  }
  get(relativeUrl) {
    return axios.get(this._dataUrl(relativeUrl), { withCredentials: true });
  }
  post(relativeUrl, data) {
    return axios.post(this.url(relativeUrl), data);
  }
}

export default {
  install: function(Vue) {
    Object.defineProperty(Vue.prototype, "$api", { value: new AppService() });
  },
};
