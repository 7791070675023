<template>
  <div style="margin-top: 30px;">
   <p>{{ $t('online.bmiCalculator.description') }}</p>
   <v-text-field
      :label="$t('online.bmiCalculator.heightCm')"
      v-model="heightCm"
      type="number"
      clearable
    />
    <v-text-field
      :label="$t('online.bmiCalculator.weightKg')"
      v-model="weightKg"
      type="number"
      clearable
    />
    <div>
      {{ $t('online.bmiCalculator.resultBmi') }}: <b> {{ resultingBmi }} </b><br>
      {{ $t('online.bmiCalculator.resultCategory') }}: <b> {{ resultingCategory }} </b>
    </div>

    <v-spacer/>

    <h2>{{ $t('online.bmiCalculator.weightsByHightTitle') }}</h2>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('online.bmiCalculator.category') }}
            </th>
            <th class="text-left">
              BMI
            </th>
            <th class="text-left">
              {{ $t('online.bmiCalculator.calculatedWeightKg') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t('online.bmiCalculator.underweight') }}</td>
            <td>0 - 18.49</td>
            <td>{{ calculatedWeights[0].min }} - {{ calculatedWeights[0].max }}</td>
          </tr>
          <tr>
            <td>{{ $t('online.bmiCalculator.normalWeight') }}</td>
            <td>18.5 - 24.9</td>
            <td>{{ calculatedWeights[1].min }} - {{ calculatedWeights[1].max }}</td>
          </tr>
          <tr>
            <td>{{ $t('online.bmiCalculator.overweight') }}</td>
            <td>25 - 29.9</td>
            <td>{{ calculatedWeights[2].min }} - {{ calculatedWeights[2].max }}</td>
          </tr>
          <tr>
            <td>{{ $t('online.bmiCalculator.obesity1') }}</td>
            <td>30 - 34.9</td>
            <td>{{ calculatedWeights[3].min }} - {{ calculatedWeights[3].max }}</td>
          </tr>
          <tr>
            <td>{{ $t('online.bmiCalculator.obesity2') }}</td>
            <td>35 - 39.9</td>
            <td>{{ calculatedWeights[4].min }} - {{ calculatedWeights[4].max }}</td>
          </tr>
          <tr>
            <td>{{ $t('online.bmiCalculator.obesity3') }}</td>
            <td>40 +</td>
            <td>{{ calculatedWeights[5].min }} +</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

  </div>
</template>

<script>

export default {
  name: "BmiCalculator",
  data() {
    return {
      heightCm: 173,
      weightKg: 60,  
    };
  },
  computed: {
    resultingBmi() {
      return this.round(this.weightKg / ( (this.heightCm / 100) ** 2));
    },
    resultingCategory() {
      let bmi = this.resultingBmi;

      if (bmi < 18.5) {
        return this.$t('online.bmiCalculator.underweight');
      };

      if (bmi < 25) {
        return this.$t('online.bmiCalculator.normalWeight');
      };

      if (bmi < 30) {
        return this.$t('online.bmiCalculator.overweight');
      };

      if (bmi < 35) {
        return this.$t('online.bmiCalculator.obesity1');
      };

      if (bmi < 40) {
        return this.$t('online.bmiCalculator.obesity2');
      };
      
      return this.$t('online.bmiCalculator.obesity3');
    },
    calculatedWeights() {
      return [
        {
          min: 0,
          max: this.calculateWeightByBmi(18.4999)
        },
        {
          min: this.calculateWeightByBmi(18.5),
          max: this.calculateWeightByBmi(24.9999)
        },
        {
          min: this.calculateWeightByBmi(25),
          max: this.calculateWeightByBmi(29.9999)
        },
        {
          min: this.calculateWeightByBmi(30),
          max: this.calculateWeightByBmi(34.9999)
        },
        {
          min: this.calculateWeightByBmi(35),
          max: this.calculateWeightByBmi(39.9999)
        },
        {
          min: this.calculateWeightByBmi(40),
        },
      ];
    }
  },
  methods: {
    round(input) {
      return Math.round(input * 100) / 100;
    },
    calculateWeightByBmi(bmi) {
      return this.round(bmi * ( (this.heightCm / 100) ** 2));
    }
  },
};

</script>

<style scoped>

h2 {
  margin-top: 30px;
  margin-bottom: 20px;
}

</style>