<template>
  <div>
    <h2>{{ $t('online.percentageCalculator.percentageFromSum.title') }}</h2>
    <p>{{ percentageFromSumDescription }}</p>
    <v-text-field
      :label="$t('online.percentageCalculator.baseSum')"
      v-model="percentageFromSum.baseSum"
      type="number"
      clearable
    />
    <v-text-field
      :label="$t('online.percentageCalculator.percentage')"
      v-model="percentageFromSum.percentage"
      type="number"
      clearable
    />
    <div>
      {{ $t('online.percentageCalculator.result') }}: <b> {{ percentageFromSumResult }} </b>
    </div>

    <v-spacer/>

    <h2>{{ $t('online.percentageCalculator.percentageAddition.title') }}</h2>
    <p>{{ percentageAdditionDescription }}</p>
    <p>{{ $t('online.percentageCalculator.percentageAddition.note') }}</p>
    <v-text-field
      :label="$t('online.percentageCalculator.baseSum')"
      v-model="percentageAddition.baseSum"
      type="number"
      clearable
    />
    <v-text-field
      :label="$t('online.percentageCalculator.percentage')"
      v-model="percentageAddition.percentage"
      type="number"
      clearable
    />
    <div>
      {{ $t('online.percentageCalculator.result') }}: <b> {{ percentageAdditionResult }} </b>
    </div>

    <v-spacer/>

    <h2>{{ $t('online.percentageCalculator.percentageCount.title') }}</h2>
    <p>{{ percentageCountDescription }}</p>
    <v-text-field
      :label="$t('online.percentageCalculator.baseSum')"
      v-model="percentageCount.baseSum"
      type="number"
      clearable
    />
    <v-text-field
      :label="$t('online.percentageCalculator.partialSum')"
      v-model="percentageCount.partialSum"
      type="number"
      clearable
    />
    <div>
      {{ $t('online.percentageCalculator.result') }}: <b> {{ percentageCountResult }}% </b>
    </div>

    <v-spacer/>

    <h2>{{ $t('online.percentageCalculator.percentageGain.title') }}</h2>
    <p>{{ percentageGainDescription }}</p>
    <v-text-field
      :label="$t('online.percentageCalculator.baseSum')"
      v-model="percentageGain.baseSum"
      type="number"
      clearable
    />
    <v-text-field
      :label="$t('online.percentageCalculator.endSum')"
      v-model="percentageGain.endSum"
      type="number"
      clearable
    />
    <div>
      {{ $t('online.percentageCalculator.result') }}: <b> {{ percentageGainResult }}% </b>
    </div>

  </div>
</template>

<script>

export default {
  name: "PercentageCalculator",
  data() {
    return {
      percentageFromSum: {
        baseSum: 128,
        percentage: 5,
      },
      percentageAddition: {
        baseSum: 521,
        percentage: 16,
      },
      percentageCount: {
        baseSum: 332,
        partialSum: 34,
      },
      percentageGain: {
        baseSum: 425,
        endSum: 472,
      },
    };
  },
  computed: {
    percentageFromSumDescription() {
      let baseSum = this.percentageFromSum.baseSum;
      let percentage = this.percentageFromSum.percentage;
      return this.$t('online.percentageCalculator.percentageFromSum.description', [ percentage, baseSum ] );
    },
    percentageFromSumResult() {
      let baseSum = this.percentageFromSum.baseSum;
      let percentage = this.percentageFromSum.percentage;
      return percentage / 100 * baseSum;
    },
    percentageAdditionDescription() {
      let baseSum = this.percentageAddition.baseSum;
      let percentage = this.percentageAddition.percentage;
      return this.$t('online.percentageCalculator.percentageAddition.description', [ baseSum, percentage ] );
    },
    percentageAdditionResult() {
      let baseSum = parseFloat(this.percentageAddition.baseSum);
      let percentage = parseFloat(this.percentageAddition.percentage);
      return baseSum + (percentage / 100 * baseSum);
    },
    percentageCountDescription() {
      let baseSum = this.percentageCount.baseSum;
      let partialSum = this.percentageCount.partialSum;
      return this.$t('online.percentageCalculator.percentageCount.description', [ partialSum, baseSum ] );
    },
    percentageCountResult() {
      let baseSum = parseFloat(this.percentageCount.baseSum);
      let partialSum = parseFloat(this.percentageCount.partialSum);
      return partialSum / baseSum * 100;
    },
    percentageGainDescription() {
      let baseSum = this.percentageGain.baseSum;
      let endSum = this.percentageGain.endSum;
      return this.$t('online.percentageCalculator.percentageGain.description', [ endSum, baseSum ] );
    },
    percentageGainResult() {
      let baseSum = parseFloat(this.percentageGain.baseSum);
      let endSum = parseFloat(this.percentageGain.endSum);
      let gain = endSum - baseSum;
      return gain / baseSum * 100;
    },
  },
};

</script>

<style scoped>
h2 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}
</style>