<template>
  <v-layout row wrap>
    <v-menu
      v-model="showDatePicker"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :label="label"
          readonly
          :value="chosenValueFormated"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        :locale="locale"
        :first-day-of-week="firstDayOfWeek"
        v-model="chosenValue"
        no-title
        @input="onInput"
      ></v-date-picker>
    </v-menu>
  </v-layout>
</template>
<script>
  export default {
    name: "DateTextField",
    props: {
      label: {
        type: String,
        default: "",
      },
      locale: {
        type: String,
        default: "en-in",
      },
      firstDayOfWeek: {
        type: [String, Number],
        default: 0,
      },
      value: String,
    },  
    data() {
      return {
        showDatePicker: false,
        chosenValue: null,
      };
    },
    created() {
      this.chosenValue = this.value;
    },
    computed: {
      chosenValueFormated() {
        return this.chosenValue ? this.formatDate(this.chosenValue) : "";
      },
    },
    methods: {
      formatDate(dateStr) {
        let date = new Date(dateStr);
        return date.toLocaleDateString(this.locale);
      },
      onInput() {
        this.showDatePicker = false;
        this.$emit("input", this.chosenValue);
      }
    }
  };
</script>