<template>

  <div class="adsense-placeholder">
      <Adsense
        :data-ad-client="dataAdClient"
        :data-ad-slot="dataAdSlot"
        :data-ad-format="dataAdFormat"
        :data-full-width-responsive="dataFullWidthResponsive"
      >
      </Adsense>
  </div>

</template>

<script>

export default {
  name: "ConditionalAdsense",
  props: {
    dataAdClient: String,
    dataAdSlot: String,
    dataAdFormat: String,
    dataFullWidthResponsive: String,
  },
  data() {
    return {
      environment: process.env.VUE_APP_ENVIRONMENT,
    };
  },
};
</script>

<style scoped>

.adsense-placeholder {
  min-height: 280px;
}

</style>
