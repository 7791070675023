<template>

  <DoubleColumnLayout>

    <v-container>

        <!-- Kalkules WP Top Banner -->
        <ConditionalAdsense
          data-ad-client="ca-pub-1338414232761221"
          data-ad-slot="3661147294"
          data-ad-format="auto"
          data-full-width-responsive="true"
        >
        </ConditionalAdsense>

      <h1>{{ $t('online.bmiCalculator.metaTitle') }}</h1>
      <bmi-calculator />

    </v-container>

    <template v-slot:right>
      <AppList />
    </template>

  </DoubleColumnLayout>

</template>

<script>

import AppList from "@/components/AppList.vue";
import ConditionalAdsense from "@/components/ConditionalAdsense.vue";
import BmiCalculator from "@/components/calculators/BmiCalculator.vue";
import DoubleColumnLayout from "@/components/DoubleColumnLayout.vue";

export default {
  name: "BmiCalculatorView",
  components: {
    AppList,
    ConditionalAdsense,
    BmiCalculator,
    DoubleColumnLayout,
  },
  metaInfo() {
    return {
      title: this.$t('online.bmiCalculator.metaTitle'),
      meta: [
        { property: "og:title", content: this.$t('online.bmiCalculator.metaTitle') },
        { property: "og:description", content: this.$t('online.bmiCalculator.metaDescription') },
        { name: "twitter:title", content: this.$t('online.bmiCalculator.metaTitle') },
        { name: "twitter:description", content: this.$t('online.bmiCalculator.metaDescription') },
        { itemprop: "name", content: this.$t('online.bmiCalculator.metaTitle') },
        { itemprop: "description", content: this.$t('online.bmiCalculator.metaDescription') },
      ],
    };
  },
};

</script>