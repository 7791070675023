import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function checkDefaultLanguage(languages) {
  let matched = null;

  languages.forEach((lang) => {
    if (lang === navigator.language) {
      matched = lang;
    }
  });

  if (!matched) {
    languages.forEach((lang) => {
      let languagePartials = navigator.language.split("-")[0];
      if (lang === languagePartials) {
        matched = lang;
      }
    });
  }

  if (!matched) {
    languages.forEach((lang) => {
      let languagePartials = navigator.language.split("-")[0];
      if (lang.split("-")[0] === languagePartials) {
        matched = lang;
      }
    });
  }

  return matched;
}

const DATE_TIME_FORMATS = {
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
  },
  de: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
  },
  fr: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
  },
  cs: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
  },
  sk: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
  },
};

const MESSAGES = loadLocaleMessages();

export const SUPPORTED_LOCALES = Object.getOwnPropertyNames(MESSAGES);
export const DEFAULT_LOCALE =
  checkDefaultLanguage(SUPPORTED_LOCALES) ||
  process.env.VUE_APP_I18N_LOCALE ||
  "en";

export default new VueI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: MESSAGES,
  dateTimeFormats: DATE_TIME_FORMATS,
  pluralizationRules: {
    "cs": function(choice, choicesLength) {
      if (choice == 0) {
        return 2;
      }
      if (choice == 1) {
        return 0;
      }
      if (choice < 5) {
        return 1;
      } else {
        return 2;
      }
    },
    "sk": function(choice, choicesLength) {
      if (choice == 0) {
        return 2;
      }
      if (choice == 1) {
        return 0;
      }
      if (choice < 5) {
        return 1;
      } else {
        return 2;
      }
    },
    "hr": function(choice, choicesLength) {

      // ending with 1, except 11 | ending with 2-4 (except 12, 13, 14) | 0, 5-19, everything else

      if (choice == 0) {
        return 2;
      }

      if (choice >= 5 && choice <= 19) {
        return 2;
      }

      let choiceEnd = choice % 10;

      if (choiceEnd == 1) {
        return 0;
      }

      if (choiceEnd >=2 && choiceEnd <= 4) {
        return 1;
      }

      return 2;
    },
  },
});
