<template>
  <div style="display: block; width: 500px; margin-left: auto; margin-right: auto;">
    <h1>Derivative Calculator</h1>
    <v-row align="center">
      <div>
        f1 =   
      </div>  
      <div>
        <math-formula elementId="inputF1" v-model="f1"></math-formula>
      </div>
    </v-row>
    <v-row align="center">
      <div>
        f2 =   
      </div>  
      <div>
        <math-formula elementId="inputF2" v-model="f2"></math-formula>
      </div>
    </v-row>
    <v-row align="center">
      <div>
        f1 = {{ f1 }}  
      </div>  
    </v-row>
    <v-row align="center">
      <div>
        f2 = {{ f2 }}  
      </div>  
    </v-row>
  </div>
</template>

<script>

import MathFormula from "@/components/MathFormula.vue";

export default {
  name: "EnDerivativeCalculator",
  components: {
    MathFormula,
  },
  data() {
    return {
      f1: "5+3",
      f2: "6+4",
    };
  }
};

</script>
