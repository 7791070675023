<template>
  <span :id="elementId"></span>
</template>

<script>

const SCRIPT_ID = "mathquillScript";

export default {
  name: "MathFormula",
  props: {
    elementId: {
      type: String,
      required: true,
    },
    value: String,
  },
  data() {
    return {
      MQ: null,
      mathField: null,
      blockBinding: false,
    };
  },
  mounted() {

    let externalScript = document.getElementById(SCRIPT_ID);

    if (externalScript == null) {
      externalScript = document.createElement('script');
      externalScript.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js');
      document.head.appendChild(externalScript);

      externalScript = document.createElement('script');
      externalScript.setAttribute('id', SCRIPT_ID);
      externalScript.setAttribute('src', '/mathquill/mathquill.min.js');
      document.head.appendChild(externalScript);

      externalScript = document.createElement('link');
      externalScript.setAttribute('rel', 'stylesheet');
      externalScript.setAttribute('href', '/mathquill/mathquill.css');
      document.head.appendChild(externalScript);
    }
  },
  created() {
    this.tryInit();  
  },
  methods: {
    tryInit() {
      if (typeof MathQuill !== 'undefined') {
        this.MQ = MathQuill.getInterface(2);  
        var mathElement = document.getElementById(this.elementId);
        this.mathField = this.MQ.MathField(mathElement, {
          handlers: {
            edit: this.onEdit,
          },
        });
        this.blockBinding = true;
        this.mathField.latex(this.value);
        this.blockBinding = false;
      } else {
        setTimeout(this.tryInit, 100);
      }
    },
    onEdit() {
      if (!this.blockBinding) {
        this.$emit("input", this.mathField.latex()); 
      }
    }
  },  
};

</script>
