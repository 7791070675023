<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-container>
        <v-row>
          <v-col align-self="center">
            <v-toolbar-title>
              <a href="/" style="text-decoration: none;">Kalkules.com</a>
            </v-toolbar-title>
          </v-col>
          <v-col>
            <v-row>
              <v-col align-self="center">
                <v-row>
                  <v-spacer />
                  <v-btn
                    href="/contact"
                    color="primary"
                    text
                    style="color: white !important;"
                  >{{ $t("header.contact") }}</v-btn>
                </v-row>
              </v-col>
              <v-col sm="4" md="2" align-self="center">
                <language-selector style="margin-top: 20px;" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-content>
      <router-view></router-view>
    </v-content>

    <v-footer>
      <v-container>
        <v-row>
          <div>&copy; {{ new Date().getFullYear() }} by Jardo</div>
          <v-spacer />
        </v-row>
        <!-- Toplist -->
        <conditional-mock :mock="environment == 'localhost'">
          <div style="display: none;">
            <a href="https://www.toplist.sk/" target="_top"><img src="https://toplist.sk/dot.asp?id=1227204" border="0" alt="TOPlist" width="1" height="1"/></a>
          </div>
        </conditional-mock>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import LanguageSelector from "@/components/LanguageSelector.vue";
import ConditionalMock from "@/components/ConditionalMock.vue";

export default {
  name: "App",
  metaInfo: {
    meta: [
      { property: "og:type", content: "website" },
      {
        property: "og:url",
        content:
          process.env.VUE_APP_BASE_URL +
          window.location.pathname +
          window.location.search,
      },
      { name: "twitter:card", content: "summary" },
      {
        name: "twitter:site",
        content:
          process.env.VUE_APP_BASE_URL +
          window.location.pathname +
          window.location.search,
      },
    ],
  },
  components: {
    "language-selector": LanguageSelector,
    ConditionalMock,
  },
  computed: mapState(["locale"]),

  data: () => ({
    environment: process.env.VUE_APP_ENVIRONMENT,
  }),

  created() {
    // init locale from storage
    this.$i18n.locale = this.$store.state.locale;
    document.documentElement.lang = this.$store.state.locale;
  },

  watch: {
    locale() {
      this.$router.replace({ params: { lang: this.locale } }).catch(() => {});
      document.documentElement.lang = this.locale;
    },
  },
};
</script>

<style>

.v-toolbar__title a {
  color: white !important;
}

</style>
