import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import store from "./store";
import Ads from 'vue-google-adsense';

Vue.config.productionTip = false;

window.__IS_PRERENDERING = window.__PRERENDER_STATUS !== undefined;
Vue.prototype.$isPrerendering = window.__IS_PRERENDERING;

Vue.use(require('vue-script2'));
Vue.use(Ads.Adsense);

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: (h) => h(App),
  mounted: () => {
    window.setTimeout(function() {
      document.dispatchEvent(new Event("x-app-rendered"));
    }, 2000);
  },
}).$mount("#app");
