<template>
    <div>
      <h2>{{ $t('online.dateCalculator.dateDifference') }}</h2>
      <p>{{ $t('online.dateCalculator.dateDifferenceDescription') }}</p>
      <date-text-field 
        :label="$t('online.dateCalculator.startDate')" 
        v-model="startDateStr" 
        :locale="locale" 
        :first-day-of-week="firstDayOfWeek"
      />
      <date-text-field
        :label="$t('online.dateCalculator.endDate')" 
        v-model="endDateStr"
        :locale="locale"
        :first-day-of-week="firstDayOfWeek"
      />
      <div>
        {{ $t('online.dateCalculator.complexDifference') }}: <b> {{ complexDifference }} </b><br>
        <br>
        {{ $t('online.dateCalculator.differenceDays') + ': ' + daysBetween }}<br>
        {{ $t('online.dateCalculator.differenceWeeks') + ': ' + weeksBetween }}<br>
        {{ $t('online.dateCalculator.differenceMonths') + ': ' + monthsBetween }}<br>
        {{ $t('online.dateCalculator.differenceYears') + ': ' + yearsBetween }}<br>
      </div>

      <v-spacer/>

      <h2>{{ $t('online.dateCalculator.addOrSubtract') }}</h2>
      <p>{{ $t('online.dateCalculator.addOrSubtractDescription') }}</p>
      <date-text-field 
        :label="$t('online.dateCalculator.baseDate')" 
        v-model="baseDateStr" 
        :locale="locale" 
        :first-day-of-week="firstDayOfWeek"
      />
      <v-radio-group v-model="operationAdd">
        <v-radio :label="$t('online.dateCalculator.add')" :value="true"/>
        <v-radio :label="$t('online.dateCalculator.subtract')" :value="false"/>
      </v-radio-group>
      <v-radio-group v-model="unit">
        <v-radio :label="$t('online.dateCalculator.unitDays')" value="day"/>
        <v-radio :label="$t('online.dateCalculator.unitWeeks')" value="week"/>
        <v-radio :label="$t('online.dateCalculator.unitMonths')" value="month"/>
        <v-radio :label="$t('online.dateCalculator.unitYears')" value="year"/>
      </v-radio-group>
      <v-text-field
        v-model="unitNumber"
        :label="$t('online.dateCalculator.unitNumber')"
        type="number"
      />
      <br>
      <div>
        {{ $t('online.dateCalculator.calculatedDate') }}: <b> {{ calculatedDate }} </b>
        <br>
        <br>
      </div>

    </div>
</template>

<script>

var dayjs = require("dayjs");
import DateTextField from "@/components/DateTextField.vue";

const MS_PER_DAY = 1000*60*60*24;
const DAYS_PER_WEEK = 7;
const DAYS_PER_MONTH = 30;
const DAYS_PER_YEAR = 365;

export default {
  name: "DateCalculator",
  components: {
    DateTextField
  },
  props: {
    locale: {
      type: String,
      default: "en-in",
    },
    firstDayOfWeek: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      startDateStr: null,
      endDateStr: null,
      baseDateStr: null,
      operationAdd: true,
      unit: "day",
      unitNumber: 5,
    };
  },
  created() {
    let today = new Date().toISOString().substring(0, 10);
    this.startDateStr = today;
    this.endDateStr = today;
    this.baseDateStr = today;
  },
  computed: {
    startDate() {
      return dayjs(this.startDateStr);
    },
    endDate() {
      return dayjs(this.endDateStr);
    },
    complexDifference() {

      let startDate = this.startDate;
      let endDate = this.endDate;

      if (startDate.isAfter(endDate)) {
        startDate = this.endDate;
        endDate = this.startDate;
      }

      let yearCount = endDate.diff(startDate, "year");
      startDate = startDate.add(yearCount, "year");

      let monthCount = endDate.diff(startDate, "month");
      startDate = startDate.add(monthCount, "month");

      let weekCount = endDate.diff(startDate, "week");
      startDate = startDate.add(weekCount, "week");

      let dayCount = endDate.diff(startDate, "day");

      let result = "";

      if (yearCount > 0) {
        result += this.$tc('online.dateCalculator.years', yearCount);
      }

      if (monthCount > 0) {
        result += ' ' + this.$tc('online.dateCalculator.months', monthCount);
      }

      if (weekCount > 0) {
        result += ' ' + this.$tc('online.dateCalculator.weeks', weekCount);
      }

      if (dayCount > 0 || result == "") {
        result += ' ' + this.$tc('online.dateCalculator.days', dayCount);
      }

      return result;
    },
    daysBetween() {
      return this.endDate.diff(this.startDate, "day");
    },
    weeksBetween() {
      return this.endDate.diff(this.startDate, "week");
    },
    monthsBetween() {
      return this.endDate.diff(this.startDate, "month");
    },
    yearsBetween() {
      return this.endDate.diff(this.startDate, "year");
    },
    calculatedDate() {

      let date = dayjs(this.baseDateStr);

      if (this.operationAdd) {
        date = date.add(this.unitNumber, this.unit);
      } else {
        date = date.subtract(this.unitNumber, this.unit);
      }

      return date.toDate().toLocaleDateString(this.locale);
    }
  },
  methods: {
    round(input) {
      return Math.round(input * 100) / 100;
    }
  },
};

</script>

<style scoped>
h2 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}
</style>