import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import Home from "../views/Home.vue";
import History from "../views/History.vue";
import Application from "../views/Application.vue";
import Download from "../views/Download.vue";
import BmiCalculatorView from "../views/online/BmiCalculatorView.vue";
import EnDerivativeCalculator from "../views/online/EnDerivativeCalculator.vue";
import DateCalculatorView from "../views/online/DateCalculatorView.vue";
import EuroCalculatorView from "../views/online/EuroCalculatorView.vue";
import NumberTextQuizEsView from "../views/online/NumberTextQuizEsView.vue";
import PercentageCalculatorView from "../views/online/PercentageCalculatorView.vue";
import store from "@/store";
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from "@/i18n";

import ApiService from "../plugins/apiservice.js";

Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(ApiService);

function switchLocale(to, from, next) {
  let lang = to.params.lang;
  if (!lang) {
    if (window.__IS_PRERENDERING) {
      // reset language to default when prerendering
      store.dispatch("changeLocale", DEFAULT_LOCALE);
    }
    next();
    return;
  }

  if (SUPPORTED_LOCALES.includes(lang)) {
    if (store.state.locale !== lang) {
      store.dispatch("changeLocale", lang);
    }
    return next();
  }

  return next(to.path.replace(lang, store.state.locale));
}

const routes = [
  {
    path: "/app/:publicId/history/:lang?",
    name: "History",
    beforeEnter: switchLocale,
    component: History,
  },
  {
    path: "/app/:publicId/:lang?",
    name: "Application",
    beforeEnter: switchLocale,
    component: Application,
  },
  {
    path: "/checkout/:productId/:lang?",
    name: "Checkout",
    beforeEnter: switchLocale,
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/Checkout.vue"),
  },
  {
    path: "/checkout-success",
    name: "CheckoutSuccess",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/CheckoutSuccess.vue"),
  },
  {
    path: "/contact/:lang?",
    name: "Contact",
    beforeEnter: switchLocale,
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/:lang?",
    name: "Home",
    component: Home,
    beforeEnter: switchLocale,
  },
  {
    path: "/download/:publicId/:file",
    name: "Download",
    component: Download,
  },
  // -------- BMI Calculator ---------
  {
    path: "/:lang/online/bmi-calculator",
    name: "BmiCalculatorView",
    component: BmiCalculatorView,
    beforeEnter: switchLocale,
  },
  {
    path: "/:lang/online/calculadora-imc",
    name: "BmiCalculatorViewEs",
    component: BmiCalculatorView,
    beforeEnter: switchLocale,
  },
  {
    path: "/:lang/online/vypocet-bmi-kalkulacka",
    name: "BmiCalculatorViewSkCs",
    component: BmiCalculatorView,
    beforeEnter: switchLocale,
  },
  {
    path: "/:lang/online/izracun-bmi-kalkulator",
    name: "BmiCalculatorViewHr",
    component: BmiCalculatorView,
    beforeEnter: switchLocale,
  },
  // -------- Derivative Calculator ---------
  {
    path: "/en/online/derivative-calculator",
    name: "EnDerivativeCalculator",
    component: EnDerivativeCalculator,
  },
  // -------- Date Calculator ---------
  {
    path: "/:lang/online/date-calculator",
    name: "DateCalculatorViewEn",
    component: DateCalculatorView,
    beforeEnter: switchLocale,
  },
  {
    path: "/:lang/online/calculadora-de-fechas",
    name: "DateCalculatorViewEs",
    component: DateCalculatorView,
    beforeEnter: switchLocale,
  },
  {
    path: "/:lang/online/datumova-kalkulacka",
    name: "DateCalculatorViewSkCs",
    component: DateCalculatorView,
    beforeEnter: switchLocale,
  },
  {
    path: "/:lang/online/kalkulator-datuma",
    name: "DateCalculatorViewHr",
    component: DateCalculatorView,
    beforeEnter: switchLocale,
  },
  // -------- Euro Calculator ---------
  {
    path: "/:lang/online/euro-converter",
    name: "EuroCalculatorViewEn",
    component: EuroCalculatorView,
    beforeEnter: switchLocale,
  },
  {
    path: "/:lang/online/conversor-de-euros",
    name: "EuroCalculatorViewEs",
    component: EuroCalculatorView,
    beforeEnter: switchLocale,
  },
  {
    path: "/:lang/online/euro-kalkulacka",
    name: "EuroCalculatorViewSkCs",
    component: EuroCalculatorView,
    beforeEnter: switchLocale,
  },
  {
    path: "/:lang/online/euro-kalkulator",
    name: "EuroCalculatorViewHr",
    component: EuroCalculatorView,
    beforeEnter: switchLocale,
  },
  // -------- Spanish Number Quiz ---------
  {
    path: "/:lang/online/spanish-numbers-quiz",
    name: "NumberTextQuizEsViewEn",
    component:NumberTextQuizEsView,
    beforeEnter: switchLocale,
  },
  {
    path: "/:lang/online/cuestionario-de-numeros-en-espanol",
    name: "NumberTextQuizEsViewEs",
    component:NumberTextQuizEsView,
    beforeEnter: switchLocale,
  },
  // -------- Percentage Calculator ---------
  {
    path: "/:lang/online/percentage-calculator",
    name: "PercentageCalculatorViewEn",
    component: PercentageCalculatorView,
    beforeEnter: switchLocale,
  },
  {
    path: "/:lang/online/kalkulacka-vypocet-procent",
    name: "PercentageCalculatorViewCs",
    component: PercentageCalculatorView,
    beforeEnter: switchLocale,
  },
  {
    path: "/:lang/online/calculadora-de-porcentajes",
    name: "PercentageCalculatorViewEs",
    component: PercentageCalculatorView,
    beforeEnter: switchLocale,
  },
  {
    path: "/:lang/online/kalkulacka-vypocet-percent",
    name: "PercentageCalculatorViewSk",
    component: PercentageCalculatorView,
    beforeEnter: switchLocale,
  },
  {
    path: "/:lang/online/kalkulator-izracun-postotka",
    name: "PercentageCalculatorViewHr",
    component: PercentageCalculatorView,
    beforeEnter: switchLocale,
  },
];

const router = new VueRouter({
  mode: "history",
  routes: routes,
});

export default router;
