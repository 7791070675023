<template>
  <v-container>
    <v-row>
      <v-col lg="8" cols="12">
        <v-sheet elevation="3">
          <div>
            <slot></slot>
          </div>
        </v-sheet>
      </v-col>
      <v-col lg="4" cols="12">
        <v-sheet elevation="3">
          <!-- Kalkules WP side panel banner -->
          <ConditionalAdsense
            data-ad-client="ca-pub-1338414232761221"
            data-ad-slot="2260435712"
            data-ad-format="auto"
            data-full-width-responsive="true"
          >
          </ConditionalAdsense>
          <slot name="right"></slot>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ConditionalAdsense from "@/components/ConditionalAdsense.vue";

export default {
  name: "DoubleColumnLayout",
  components: {
    ConditionalAdsense,
  },
}
</script>