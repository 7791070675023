<template>
  <DoubleColumnLayout>
    <template v-slot:default>
      <AppPage publicId="kalkules" />
    </template>
    <template v-slot:right>
      <AppList excludedAppId="kalkules" />
    </template>
  </DoubleColumnLayout>
</template>

<script>
// @ is an alias to /src
import AppList from "@/components/AppList.vue";
import AppPage from "@/components/AppPage.vue";
import DoubleColumnLayout from "@/components/DoubleColumnLayout.vue";

export default {
  name: "Home",
  components: {
    AppList,
    AppPage,
    DoubleColumnLayout,
  },
};
</script>
