<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>{{ historyData.appName ? $t("history.caption", [historyData.appName]) : '' }}</h1>
        <div v-if="historyData.appName" style="margin-top: 10px; margin-bottom: 20px;">
          <a
            v-if="historyData.appName"
            :href="'/app/' + $route.params.publicId"
          >{{ $t("history.downloadText", [historyData.appName]) }}</a>
        </div>
        <div v-for="(version, i) in historyData.versions" :key="i" class="version">
          <h2>{{ version.name + " (" + $d(new Date(version.releaseDate), "short") + ")" }}</h2>
          <div v-if="version.description" v-html="version.description" />
          <h4 v-if="version.newFeatures">{{ $t("history.newFeatures") }}</h4>
          <div v-if="version.newFeatures" v-html="version.newFeatures" />
          <h4 v-if="version.fixedBugs">{{ $t("history.fixedBugs") }}</h4>
          <div v-if="version.fixedBugs" v-html="version.fixedBugs" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "History",
  computed: {
    locale: function() {
      return this.$store.state.locale;
    },
    metaTitle: function() {
      return this.historyData.appName
        ? this.$t("history.caption", [this.historyData.appName])
        : undefined;
    },
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      historyData: {
        appName: null,
        versions: [],
      },
    };
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { property: "og:title", content: this.metaTitle },
        { name: "twitter:title", content: this.metaTitle },
        { itemprop: "name", content: this.metaTitle },
      ],
    };
  },
  methods: {
    async fetchData() {
      const response = await this.$api.get(
        "app/history/" +
          this.$route.params.publicId +
          "?lang=" +
          this.$store.state.locale,
      );
      this.historyData = await response.data;
    },
  },
  watch: {
    locale() {
      this.fetchData();
    },
  },
};
</script>

<style scoped>
h4 {
  margin-top: 15px;
  margin-bottom: 5px;
}

.version {
  margin-bottom: 20px;
}
</style>