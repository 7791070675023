<template>
  <v-row>
    <v-col>
      <v-select
        :items="items"
        v-model="selectedFileId"
        :label="$t('fileDownloader.selectFileType')"
      ></v-select>
    </v-col>
    <v-col align-self="center">
      <v-btn
        :href="downloadLink"
        :disabled="selectedFileId == null"
        color="primary"
      >
        {{ $t("fileDownloader.download") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FileDownloader",
  props: {
    appPublicId: {
      type: String,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      selectedFileId: null,
    };
  },
  created() {
    this.initComboboxData();
  },
  watch: {
    files: function() {
      this.initComboboxData();
    },
  },
  computed: {
    downloadLink: function() {
      if (this.selectedFileId) {
        return "/download/" + this.appPublicId + "/" + this.selectedFileId;
      } else {
        return "";
      }
    },
  },
  methods: {
    getFileText(file) {
      var name;

      switch (file.operatingSystem) {
        case "WIN": {
          name = "Windows - ";
          break;
        }
        case "LINUX": {
          name = "Linux - ";
          break;
        }
        case "MAC": {
          name = "IOS - ";
          break;
        }
      }

      switch (file.type) {
        case "EXE": {
          name += this.$t("fileDownloader.installationPackage");
          break;
        }
        case "ZIP": {
          name += this.$t("fileDownloader.zipPackage");
          break;
        }
        case "TAR": {
          name += this.$t("fileDownloader.tarPackage");
          break;
        }
      }

      return name;
    },
    initComboboxData() {
      var items = [];
      for (var i = 0; i < this.files.length; i++) {
        var file = this.files[i];
        items.push({
          text: this.getFileText(file),
          value: file.id,
        });
      }
      this.items = items;
      if (items.length > 0) {
        this.selectedFileId = items[0].value;
      }
    },
  },
};
</script>
