<template>
  <div>
    <div v-if="mock">
      <slot name="mock"></slot>
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConditionalMock",
  props: {
    mock: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
