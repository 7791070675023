<template>
  <v-container>
    <v-row>
      <v-col>

        <!-- Kalkules WP Top Banner -->
        <ConditionalAdsense
          data-ad-client="ca-pub-1338414232761221"
          data-ad-slot="3661147294"
          data-ad-format="auto"
          data-full-width-responsive="true"
        >
        </ConditionalAdsense>

        <h1>{{ appData.name }}</h1>
        <p>{{ appData.descriptionShort }}</p>
        <file-downloader :appPublicId="this.publicId" :files="appData.files" />
        <v-carousel hide-delimiters height="540px">
          <v-carousel-item v-for="(image, i) in appData.images" :key="i" :eager="$isPrerendering">
            <v-card light height="98%" style="margin: 5px;">
              <v-row style="height: 100%">
                <v-spacer />
                <v-col align-self="center">
                  <v-card-title class="headline" v-text="image.caption" />
                  <img :src="$api.imageUrl(image.id)" :alt="image.alt" class="feature-image" />
                  <v-card-text v-html="image.description" />
                </v-col>
                <v-spacer />
              </v-row>
            </v-card>
          </v-carousel-item>
        </v-carousel>
        <p v-html="appData.descriptionLong" class="description"></p>
        <file-downloader :appPublicId="this.publicId" :files="appData.files" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConditionalAdsense from "@/components/ConditionalAdsense.vue";
import FileDownloder from "@/components/FileDownloader.vue";
export default {
  name: "AppView",
  props: {
    publicId: String,
  },
  components: {
    "file-downloader": FileDownloder,
    ConditionalAdsense,
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      // mock app data to prevent CLS issues
      appData: {
        name: "________ _________ __________",
        descriptionShort: "_________ _________ _________ _________ _________ _________ _________ _________ _________ _________",
        files: [
          {
            id: "0",
            operatingSystem: "WIN",
            sizeBytes: 0,
            type: "EXE",
          },
        ],
        images: [],
      },
    };
  },
  computed: {
    locale: function() {
      return this.$store.state.locale;
    },
    metaTitle: function() {
      return this.appData.name;
    },
    metaDescription: function() {
      return this.appData.descriptionShort;
    },
    metaImage: function() {
      return this.appData.images.length > 0
        ? this.$api.imageUrl(this.appData.images[0].id)
        : "";
    },
  },
  metaInfo() {
    return {
      title: this.appData.name,
      meta: [
        { property: "og:title", content: this.metaTitle },
        { property: "og:description", content: this.metaDescription },
        { property: "og:image", content: this.metaImage },
        { name: "twitter:title", content: this.metaTitle },
        { name: "twitter:description", content: this.metaDescription },
        { itemprop: "name", content: this.metaTitle },
        { itemprop: "description", content: this.metaDescription },
        { itemprop: "image", content: this.metaImage },
      ],
    };
  },
  methods: {
    async fetchData() {
      const response = await this.$api.get(
        "app/details/" + this.publicId + "?lang=" + this.$store.state.locale,
      );
      this.appData = await response.data;
    },
  },
  watch: {
    locale() {
      this.fetchData();
    },
  },
};
</script>

<style scoped>
.description {
  margin-top: 40px;
}
.feature-image {
  max-width: 650px;
  max-height: 350px;
}
</style>
