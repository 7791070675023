<template>

  <DoubleColumnLayout>

    <v-container>

        <!-- Kalkules WP Top Banner -->
        <ConditionalAdsense
          data-ad-client="ca-pub-1338414232761221"
          data-ad-slot="3661147294"
          data-ad-format="auto"
          data-full-width-responsive="true"
        >
        </ConditionalAdsense>


        <h1>{{ $t('online.euroCalculator.metaTitle') }}</h1>

        <h2>{{ $t('online.euroCalculator.convertEurDefault') }}</h2>
        <p class="conversionRate">{{ conversionRateStr(defaultCurrency) }}</p>
        <simple-unit-converter sourceUnit="EUR" :targetUnit="defaultCurrency" :conversionRate="conversionRates[defaultCurrency]" />
        <simple-unit-converter :sourceUnit="defaultCurrency" targetUnit="EUR" :conversionRate="1/conversionRates[defaultCurrency]" />

        <div v-show="$t('online.euroCalculator.defaultCurrency') != 'USD'">

          <h2>{{ $t('online.euroCalculator.convertEurUsd') }}</h2>
          <p class="conversionRate">{{ conversionRateStr('USD') }}</p>
          <simple-unit-converter sourceUnit="EUR" targetUnit="USD" :conversionRate="conversionRates['USD']" />
          <simple-unit-converter sourceUnit="USD" targetUnit="EUR" :conversionRate="1/conversionRates['USD']" />

        </div>

        <h2>{{ $t('online.euroCalculator.convertEurOther') }}</h2>
        <v-select
            :items="conversionRateItems"
            v-model="selectedRate"
            :label="$t('online.euroCalculator.currency')"
        />
        <p class="conversionRate">{{ conversionRateStr(selectedRate) }}</p>
        <simple-unit-converter sourceUnit="EUR" :targetUnit="selectedRate" :conversionRate="conversionRates[selectedRate]" />
        <simple-unit-converter :sourceUnit="selectedRate" targetUnit="EUR" :conversionRate="1/conversionRates[selectedRate]" />

    </v-container>

    <template v-slot:right>
      <AppList />
    </template>

  </DoubleColumnLayout>

</template>

<script>

import AppList from "@/components/AppList.vue";
import ConditionalAdsense from "@/components/ConditionalAdsense.vue";
import DoubleColumnLayout from "@/components/DoubleColumnLayout.vue";
import SimpleUnitConverter from "@/components/SimpleUnitConverter.vue";

const HRK_EUR = 7.53;
const SKK_EUR = 30.126;

export default {
  name: "EuroCalculatorView",
  components: {
    AppList,
    ConditionalAdsense,
    DoubleColumnLayout,
    SimpleUnitConverter
  },
  data() {
    return {
      conversionRates: {
        "HRK": HRK_EUR,
        "SKK": SKK_EUR,
        "USD": 1.1782,
        "CZK": 26.081
      },
      selectedRate: 'USD',
    };
  },
  created() {
    this.fetchConversionRates();
  },
  methods: {
    async fetchConversionRates() {
      const response = await this.$api.get("data/exchange-rates");
      let rates = await response.data.rates;
      rates["HRK"] = HRK_EUR;
      rates["SKK"] = SKK_EUR;
      this.conversionRates = rates;
    },
    conversionRateStr(targetUnit) {
        const rate = this.conversionRates[targetUnit];
        const inverseRate = Math.floor(1/rate*10000) / 10000;
        return `${this.$t('online.euroCalculator.conversionRate')}: 1 EUR = ${rate} ${targetUnit}, 1 ${targetUnit} = ${inverseRate} EUR`;
    },
  },
  computed: {
      conversionRateItems: function() {
          var rates = Object.keys(this.conversionRates);
          var items = [];
          for (var i = 0; i < rates.length; i++) {
              const rate = rates[i];
              items.push({
                  text: `${this.$t('currency.' + rate)} (${rate})`,
                  value: rate
              });
          };
          items.sort((a, b) => {
              if (a.text > b.text) {
                  return 1;
              } else if (a.text < b.text) {
                  return -1;
              } else {
                  return 0;
              }
          });
          return items;
      },
      defaultCurrency: function() {
        return this.$t('online.euroCalculator.defaultCurrency');
      }
  },
  metaInfo() {
    return {
      title: this.$t('online.euroCalculator.metaTitle'),
      meta: [
        { property: "og:title", content: this.$t('online.euroCalculator.metaTitle') },
        { property: "og:description", content: this.$t('online.euroCalculator.metaDescription') },
        { name: "twitter:title", content: this.$t('online.euroCalculator.metaTitle') },
        { name: "twitter:description", content: this.$t('online.euroCalculator.metaDescription') },
        { itemprop: "name", content: this.$t('online.euroCalculator.metaTitle') },
        { itemprop: "description", content: this.$t('online.euroCalculator.metaDescription') },
      ],
    };
  },
};

</script>

<style scoped>

h2 {
    margin-top: 20px;
}

.conversionRate {
    margin-top: 10px;
    margin-bottom: 6px;
}

.v-select {
    flex: none;
    width: 300px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

</style>